import { Carousel } from "react-responsive-carousel";
import { PhotoCarouselProps } from "./PhotoCarouselProps";

export const PhotoCarousel = ({ data }: { data: PhotoCarouselProps }) => {

    const carouselList = data.imgSrc.map((v, k) => <div key={k}><img src={v} alt={v} /></div>);

    return (
        <Carousel showIndicators={false} autoPlay infiniteLoop>
            {carouselList}
        </Carousel>
    );
}