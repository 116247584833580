import { createTheme } from '@mui/material';
import OpenSansBold from '../Assets/Fonts/OpenSans-Bold.ttf';
import OpenSansRegular from '../Assets/Fonts/OpenSans-Regular.ttf';

const primaryColor = '#3A4049';
const secondaryColor = '#FFFFFF';

export const theme = createTheme({
    palette: {
        primary: {
            main: primaryColor,
        },
        secondary: {
            main: secondaryColor,
        },
    },
    typography: {
        fontFamily: 'OpenSans',
        allVariants: {
            color: primaryColor,
        },
        h3: {
            fontFamily: 'OpenSans',
            letterSpacing: '0.25em',
            fontWeight: 700
        },
        h4: {
            fontFamily: 'OpenSans',
            fontWeight: 700
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides:
                `
                @font-face {
                font-family: 'OpenSans';
                src: url(${OpenSansBold}) format('truetype');
                font-weight: 700;
                font-style: normal;
                }
            
                @font-face {
                font-family: 'OpenSans';
                src: url(${OpenSansRegular}) format('truetype');
                font-weight: 500;
                font-style: normal;
                }
            `,
        }
    }
});