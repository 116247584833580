import { Grid, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const StyledTypography = styled(Typography)(({ theme }) => ({
    textAlign: 'center'
}));

const StyledOuterGrid = styled(Grid)(({ theme }) => ({
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5%',

}));

export const QuotePage = () => {

    const { t } = useTranslation();

    return (<StyledOuterGrid container direction="column" spacing={4} >
        <Grid item>
            <StyledTypography variant="h4" >{t('quote_title')}</StyledTypography>
        </Grid>
        <Grid item>
            <StyledTypography variant="h5">
                &ldquo;{t('quote_body')}&rdquo;
            </StyledTypography>
        </Grid>
        <Grid item>
            <Typography variant="body1">
                &#8211; {t('quote_owner')}
            </Typography>
        </Grid>
        <br />
    </StyledOuterGrid>);
}