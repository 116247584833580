import { Button, Card, CardContent, CardHeader, Grid, styled, Typography } from '@mui/material';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import bg from "../../Assets/Images/pre-wedding-bg-1.jpg";

const StyledImageDiv = styled('div')({ backgroundImage: `url(${bg})`, height: 500 });

const StyledOuterGrid = styled(Grid)({
    height: 500,
    alignItems: 'center',
    justifyContent: 'space-around',
});

const InnerOuterGrid = styled(Grid)({
    alignItems: 'center',
    justifyContent: 'space-evenly',
});

const StyledCard = styled(Card)({
    height: isMobile ? undefined : '100%',
    backgroundColor: 'transparent',
    border: "none",
    boxShadow: "none",
});

const StyledRvspButton = styled(Button)(({ theme }) => ({
    borderRadius: 0,
    border: '5px solid',
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.main,
    textAlign: 'center'
}));


const DetailCard = () => {

    const { t } = useTranslation();

    const generateDetail = (title: string, content: string[]) => {
        return (<StyledCard>
            <CardHeader title={<StyledTypography variant='h4'>{title}</StyledTypography>} />
            <CardContent>
                {content.map((v, k) => <StyledTypography variant='h5' key={k}> {v}</StyledTypography>)}
            </CardContent>
        </StyledCard >);
    }

    const handleRvspButtonClick = () => {
        window.open(t('wedding_detail_rvsp_link'), "_blank")
    }

    return (
        <StyledImageDiv>
            <StyledOuterGrid container direction="column" >
                <StyledTypography variant='h3'>{t('wedding_detail_title')}</StyledTypography>
                <InnerOuterGrid container item>
                    <Grid item xs={12} md={6}>{generateDetail(t('wedding_detail_when_label'), [t('wedding_detail_when_body')])}</Grid>
                    <Grid item xs={12} md={6}>{generateDetail(t('wedding_detail_where_label'), [t('wedding_detail_where_body_1'), t('wedding_detail_where_body_2'), t('wedding_detail_where_body_3')])}</Grid>
                </InnerOuterGrid>
                <StyledRvspButton variant="outlined" size='large' onClick={handleRvspButtonClick}>
                    <StyledTypography variant='h4'>{t('wedding_rsvp_btn')} </StyledTypography>
                </StyledRvspButton>
            </StyledOuterGrid>
        </StyledImageDiv >
    );

}

export default DetailCard;