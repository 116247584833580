import { Container } from "@mui/material";
import { BannerPage } from "../Banner";
import { PhotoGalleryPage } from "../PhotoGallery";
import { QuotePage } from "../Quote";
import { WeddingDetailPage } from "../WeddingDetail";

export const MainPage = () =>
     <Container disableGutters>
          <BannerPage />
          <QuotePage />
          <PhotoGalleryPage />
          <WeddingDetailPage />
     </Container>
