import { ThemeProvider } from '@emotion/react';
import { CssBaseline, responsiveFontSizes } from '@mui/material';
import i18n from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import App from './App';
import en from './Assets/i18n/en.json';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { theme } from './Theme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

i18n.use(initReactI18next).init({
  resources: { en: en, },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false
  }
});



root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={responsiveFontSizes(theme)}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
