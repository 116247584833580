import { PhotoCarousel, PhotoCarouselProps } from "../../Components/Carousel";

import photoWeb1 from "../../Assets/Images/pre-wedding-photo-web-1.jpg";
import photoWeb2 from "../../Assets/Images/pre-wedding-photo-web-2.jpg";
import photoWeb3 from "../../Assets/Images/pre-wedding-photo-web-3.jpg";
import photoWeb4 from "../../Assets/Images/pre-wedding-photo-web-4.jpg";
import photoWeb5 from "../../Assets/Images/pre-wedding-photo-web-5.jpg";
import photoWeb6 from "../../Assets/Images/pre-wedding-photo-web-6.jpg";
import photoWeb7 from "../../Assets/Images/pre-wedding-photo-web-7.jpg";
import photoWeb8 from "../../Assets/Images/pre-wedding-photo-web-8.jpg";
import photoWeb9 from "../../Assets/Images/pre-wedding-photo-web-9.jpg";
import photoWeb10 from "../../Assets/Images/pre-wedding-photo-web-10.jpg";
import photoMobile1 from "../../Assets/Images/pre-wedding-photo-mobile-1.jpg";
import photoMobile2 from "../../Assets/Images/pre-wedding-photo-mobile-2.jpg";
import photoMobile3 from "../../Assets/Images/pre-wedding-photo-mobile-3.jpg";
import photoMobile4 from "../../Assets/Images/pre-wedding-photo-mobile-4.jpg";
import photoMobile5 from "../../Assets/Images/pre-wedding-photo-mobile-5.jpg";
import photoMobile6 from "../../Assets/Images/pre-wedding-photo-mobile-6.jpg";
import photoMobile7 from "../../Assets/Images/pre-wedding-photo-mobile-7.jpg";
import photoMobile8 from "../../Assets/Images/pre-wedding-photo-mobile-8.jpg";
import photoMobile9 from "../../Assets/Images/pre-wedding-photo-mobile-9.jpg";
import photoMobile10 from "../../Assets/Images/pre-wedding-photo-mobile-10.jpg";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { isMobile } from "react-device-detect";

const photoCarouselData: PhotoCarouselProps = {
    imgSrc: isMobile ? [photoMobile1, photoMobile2, photoMobile3, photoMobile4, photoMobile5, photoMobile6, photoMobile7, photoMobile8, photoMobile9, photoMobile10] :
        [photoWeb1, photoWeb2, photoWeb3, photoWeb4, photoWeb5, photoWeb6, photoWeb7, photoWeb8, photoWeb9, photoWeb10],
}

export const PhotoGalleryPage = () => {
    return <PhotoCarousel data={photoCarouselData} />;
}